import { Service } from "../service-interface"
import { webshopUzemeltetesInfoCards } from "../../service-info-card-interface/used_by/webshop-uzemeltetes-cards"

export const webshopUzemeltetes : Service = {
  title: 'Webshop üzemeltetés',
  description: "Maradj versenyben, törj a piacod élére! Ehhez elengedhetetlen a tapasztalt háttér csapat.",
  icon: "/images/service.svg",
  topimg: "/images/webshop_uzemeltetes.png",
  cards: webshopUzemeltetesInfoCards,
  listPoints: [
    "A piaci igényeket hangoljuk össze",
    "Ismerje meg jobban a piacot",
  ]
}
