import { ServiceInfoCardChildInterface } from "../service-info-card-interface"

const uzemeltetes1 : ServiceInfoCardChildInterface = {
  bigImg: false,
  boldDescription: "A piaci részesedés megtartásához és növeléséhez rendkívül fontos a profi technikai háttér",
  description: "Ehhez adunk folyamatos támogatást, felhő alapú szerver szolgáltatást és együtt gondolkodunk Önnel hogyan tudna nagyobb versenyelőnyre szert tenni webáruházával\n",
  displayXo: true,
  reverse: false,
  title: "Menjünk előre!",
  picture: "/images/services/uzemeltetes_card_1.jpg"
}

const uzemeltetes2 : ServiceInfoCardChildInterface = {
  bigImg: false,
  boldDescription: "Az eredményorientált megközelítés fenntartható, hatékony és valós megoldásokat jelent hosszú távon. Bármiről is legyen szó, küldetésünk minden aggodalmat levenni ügyfeleink válláról\n",
  description: "Elengedhetetlen, hogy a webes felülete minden tekintetben naprakész legyen, külső megjelenésében, funkcionálisan és tartalmilag is\n",
  displayXo: false,
  reverse: true,
  title: "Nincsen egyedül",
  picture: "/images/services/uzemeltetes_card_2.jpg"
}

export const webshopUzemeltetesInfoCards = [
  uzemeltetes1,
  uzemeltetes2
]

